const { formLabelConfig,formDataProp,formLabelConfig2,formLabelConfig3} = require('../config/couponActivity/couponTemplate.js');
module.exports = {
	data(){
		return{
			formDataConfig:formLabelConfig,//表单label配置
            //customUpdate:true,
			formLabelWidth:"120px"
		}
	},
	created(){
		this.pickerOptions.disabledDate = {}
		this.formRequestConfig.url = "/sys/couponTemplate/saveOrUpdata"
		if(this.opentype==2){
			if(this.selectdata.couponTemplateType==2){
				this.formDataConfig = formLabelConfig2
			}else if(this.selectdata.couponTemplateExpireType==0){
				this.formDataConfig = formLabelConfig3
			}else{
				this.formDataConfig = formLabelConfig
			}
			if(this.selectdata.couponTemplateScopeType==1){
				this.showTapInput = true
				this.tapInputComponentName="SelectProduct"
			}else{
				this.showTapInput = false
				this.tapInputComponentName=""
				this.formData.couponTemplateScope = ""
				this.formData.couponTemplateScopeName = ""
			}
		}
		console.log()
	},
	mounted(){
		
	},
	methods: {
		selectEvent(e,item){
			const {prop} = item
			if(prop=="couponTemplateType"){
				if(e==2){
					this.formDataConfig = formLabelConfig2
				}else{
					this.formDataConfig = formLabelConfig
				}
			}
			if(prop=='couponTemplateExpireType'){
				if(e==0){
					this.formDataConfig.splice(this.formDataConfig.findIndex(item => item.prop === 'couponTemplateExpireStartTime'), 1)
					this.formDataConfig.splice(this.formDataConfig.findIndex(item => item.prop === 'couponTemplateExpireEndTime'), 1)
				}else{
					if(this.formDataConfig.findIndex(item => item.prop === 'couponTemplateExpireStartTime')!=-1) return
					this.formDataConfig = this.formDataConfig.concat([{
						prop: "couponTemplateExpireStartTime",
						label: "开始时间",
						showType: "date",
						allowNull: true
					},
					{
						prop: "couponTemplateExpireEndTime",
						label: "结束时间",
						showType: "date",
						allowNull: true
					},])
				}
			}
			if(prop=='couponTemplateScopeType'){
				if(e==1){
					this.showTapInput = true
					this.tapInputComponentName="SelectProduct"
				}else{
					this.showTapInput = false
					this.tapInputComponentName=""
					this.formData.couponTemplateScope = ""
					this.formData.couponTemplateScopeName = ""
				}
			}
		},
		getTapInputVal(e){
			this.formData.couponTemplateScope = e.val
			this.formData.couponTemplateScopeName = e.name
			this.$forceUpdate()
		},
		// updateFrom(data,formName){
        //     console.log("更新数据");
		// 	this.$refs[formName].validate(async valid => {
		// 		if (valid) {
		// 			await this.$axios('POST',"/sys/couponTemplate/saveOrUpdata",data,true);
		// 			this.$message.success('保存成功！');
		// 			this.$emit('fchange', false);
		// 			this.formData={}
		// 		} else {
		// 			this.$message.error('请完善表单信息后重试！');
		// 		}
		// 	});
		// },
	},
	components: {
		SelectProduct: () => import('@/components/businessComponent/selectProduct.vue')
	},
}