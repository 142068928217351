const { formLabelConfig,formDataProp} = require('../config/store/ProductList.js');
module.exports = {
	data(){
		return{
			formDataConfig:formLabelConfig,//表单label配置
			customUpdate:true,
			formLabelWidth:"120px",
			// formComponentName:"productSkuList",
		}
	},
	created(){
		this.getCateGoryList()
		this.getTransportTplList()
		this.getTagsList()
		this.formData=formDataProp
		this.formRequestConfig.url = "/sys/commodity/save"
	},
	mounted(){
		
	},
	methods: {
		async getCateGoryList(){
			const res = await this.$axios('GET',"/sys/category/listCategory",{},true)
			this.formDataConfig.map(v=>{
				if(v.prop=='categoryId'){
					v.option = res
					v.lkey="categoryName"
					v.vkey="categoryId"
				}
			})
			this.$forceUpdate();
		},
		async getTransportTplList(){
			const res = await this.$axios('GET',"/sys/transport/list",{},true)
			this.formDataConfig.map(v=>{
				if(v.prop=='deliveryTemplateId'){
					v.option = res
					v.lkey="transName"
					v.vkey="transportId"
				}
			})
			this.$forceUpdate();
		},
		async getTagsList(){
			const res = await this.$axios('POST',"/sys/label/list",{},true)
			this.formDataConfig.map(v=>{
				if(v.prop=='labelId'){
					v.option = res
					v.lkey="labelName"
					v.vkey="labelId"
				}
			})
			this.$forceUpdate();
		},
		beforeSubmit(type,formData){
			this.formData.skuList = [{
				commodityName:formData.commodityName,
				skuActualStocks:formData.commodityStock,
				skuCostPrice:formData.skuCostPrice,
				skuOriPrice:formData.commodityOriginalPrice,
				skuPrice:formData.commodityActualPirce,
				skuVipPrice:formData.skuVipPrice,
				skuVolume:formData.skuVolume,
				skuWeight:formData.skuWeight,
				status:1
			}]
		},
		updateFrom(data,formName){
			this.$refs[formName].validate(async valid => {
				if (valid) {
					await this.$axios('POST',"/sys/commodity/update",data,true);
					this.$message.success('保存成功！');
					this.$emit('fchange', false);
					this.formData={}
				} else {
					this.$message.error('请完善表单信息后重试！');
				}
			});
		},
	},
	// components: {
	// 	"productSkuList": () => import("@/components/customForm/productSkuList.vue")
	// },
}