const { formLabelConfig,formDataProp} = require('../config/userManager/userLevel.js');
module.exports = {
	data(){
		return{
			formDataConfig:formLabelConfig,//表单label配置
			tapInputComponentName:"SelectTemplate",
			formLabelWidth:"120px"
		}
	},
	created(){
		this.formRequestConfig.url = "/sys/userLevel/saveOrUpdata"
	},
	methods: {
		getTapInputVal(e){
			this.formData.associatedCouponId = e.val
			this.formData.associatedCouponName = e.name
			// this.formData[this.tapInputProp] = e.name
			this.$forceUpdate()
		},
	},
	components: {
		SelectTemplate: () => import('@/components/businessComponent/selectTemplate.vue')
	},
}