const { formLabelConfig,formDataProp} = require('../config/userManager/integralSet.js');
module.exports = {
	data(){
		return{
			formDataConfig:formLabelConfig,//表单label配置
			// customUpdate:true,
		}
	},
	created(){
		// this.formData=formDataProp
		this.formRequestConfig.url = "/sys/integralSetting/submit"
	},
	mounted(){
		
	},
	methods: {
	},
	components: {
		
	},
}