const { formLabelConfig,formDataProp} = require('../config/system/role.js');
module.exports = {
	data(){
		return{
			formDataConfig:formLabelConfig,//表单label配置
			// customUpdate:true,
			formData:formDataProp
		}
	},
	created(){
		this.formRequestConfig.url = "/sys/sysRole/updateOrSave"
	},
	mounted(){
		
	},
	methods: {
		// updateFrom(data,formName){
		// 	this.$refs[formName].validate(async valid => {
		// 		if (valid) {
		// 			await this.$axios('POST',"/admin/yunke/mgr/updateAdminInfo",data,true);
		// 			this.$message.success('保存成功！');
		// 			this.$emit('fchange', false);
		// 			this.formData={}
		// 		} else {
		// 			this.$message.error('error submit!!');
		// 		}
		// 	});
		// },
	},
	components: {
		
	},
}