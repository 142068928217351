import Vue from 'vue'
import axios from 'axios'
import {formatDate,formatStrToDate,dateValid} from './formatdate.js'
const baseUrl = process.env.VUE_APP_API=="/"?'/api':process.env.VUE_APP_API
var myMixin = {
	props: {
		opentype: {//类型判断0自定义 1为新建，2为编辑，3为查看
			type: Number,
			default: 0
		},
		selectdata: {
			type: Object,
			default: () => {}
		},
		formConfig: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			aueditOptions:{
				action: baseUrl+"/file-upload/upload",
				props: {
					res: "data",
					url:'url'
				},
			},
			formRequestConfig:{
				url:"",
				methods:"POST",
				token:true
			},
			formLabelWidth: '90px',//表单label长度
			formComponentName:"",//form类型内容项自定义组件名
			tapInputComponentName:"",//tapInput类型自定义组件name
			showTapInput:false,
			imgUrl:[],
			formData: {},//表单关联配置数据，就是selectdata
			formDataConfig:[
				{
					showType:"text",//表单类型text,textarea,password,kindEditor,date,select,mSelect,imgUploader,number
					readonly:"false",//是否只读
					label:"姓名",//label标签
					prop:"name",//字段名
					allowNull:false,//是否需要验证，//是否允许空值
					testType:null,//验证类型number,digits,percent,mobile,phone,email,postal
					testTypeOption:{
						min:10,//最小
						max:30,//最大
						range:[12,89]//介于
					}//验证详细规则配置
				},
				{
					isShow:true,
					needSave:true,
					showType:"kindEditor",
					readonly:"false",
					label:"地址",
					allowNull:false,
					prop:"address"
				},
				{
					isShow:true,
					needSave:true,
					showType:"date",
					readonly:"false",
					allowNull:false,
					formatString:"yyyy-MM",//日期格式
					label:"日期",
					prop:"date"
				},
				{
					isShow:true,
					needSave:true,
					showType:"dropDownList",
					readonly:"false",
					allowNull:false,
					optionType:"default",//option选项类型：TrueFalse、default
					selectOptions:[{name:"昆明",value:0},{name:"杭州",value:1}],//下拉选项
					label:"城市",
					prop:"city"
				}
			],//表单数据相关配置
			rules: {},//表单验证规则
			uploadImgData:"",//上传图片数据
			muploadImgData:"",//多图上传数据
			uploadImg:"",
			oploadFilds:"",//图片字段
			oploadList:[],
			uploadShowList:[],//上传展示图片列表
			uploadJson: "/kindeditor/jsp/upload_json.jsp",
			fileManagerJson: "/kindeditor/jsp/file_manager_json.jsp",
			pickerOptions: {//日期选项配置
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
				shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					},
					{
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					},
					{
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}
				]
			}
		}
	},
	created(){
		if(this.opentype!=3)
			this.newFormVerification()
	},
	mounted() {
		if(this.opentype==0 || this.opentype==2 || this.opentype==3){
			this.formData = this.selectdata
			this.formDataConfig.map((v)=>{
				if(v.showType=="imgUploader"){
					this.uploadImgData = this.formData[v.prop]
				}
				if(v.showType=="mImgUploader"){
					this.muploadImgData = this.formData[v.prop]
				}
			})
		}
	},
	components:{
		ImgUpload:()=>import("@/components/imgUpload/imgUploader.vue"),
		multipleImgUploader:()=>import("@/components/imgUpload/multipleImgUploader.vue")
	},
	methods: {
		//富文本编辑操作数据
		onContentChange(val, name) {
			this.$set(this.formData, name, val)
		},
		//select    change事件
		selectEvent(e,item){
			console.log(item)
		},
		// 日期选择器时间格式化
		dateChange(e,item){
			this.formData[item.prop] = formatDate("yyyy-MM-dd hh:mm:ss",new Date(e))
		},
		//关闭dialog
		handleChangeClose() {
			this.$emit('schange', false);
		},
		//表单验证
		newFormVerification() {
			for (let i = 0; i < this.formDataConfig.length; i++) {
				let field = this.formDataConfig[i];
				this.validationRules(field, this)
			}
		},
		//图片上传成功回调
		successBack(list,res,name,ismultiple){
			if(ismultiple){
				this.uploadShowList=list
			}else{
				const arr ={uid:"",url:res.data};
				this.uploadShowList = [arr]
			}
			this.setUploadImgUrl(this.uploadShowList,name,ismultiple)
		},
		//删除图片回调
		delImgBack(res,name,ismultiple){
			this.setUploadImgUrl(res,name,ismultiple)
		},
		//设置上传图片地址
		setUploadImgUrl(res,name,ismultiple){
			let imgurls=""
			if(res.length>0){
				res.forEach((item,index)=>{
					if(imgurls!="")
						imgurls+=","
					imgurls+=item.url
				})
			}
			this.uploadImg = imgurls	
			this.oploadFilds = name
			// if(ismultiple)
			// 	this.muploadImgData = imgurls
			if(this.oploadFilds)
				this.$set(this.formData,this.oploadFilds,this.uploadImg)
		},
		//自定义tapInput点击事件
		tapInput(val,prop){
			this.showTapInput = true
			this.tapInputProp = prop
		},
		//提交保存数据
		handleChangeConfirm(formName) {
			//提交数据this.formData
			if(this.$route.query.upperField && this.$route.query.id)//如果有二级列表，新建的时候需要传入上级的标识字段upperField父级标识字段
				this.$set(this.formData,this.$route.query.upperField,this.$route.query.id)
			if(this.beforeSubmit){//是否需要在提交之前做一些额外处理
				this.beforeSubmit(this.opentype,this.formData)
			}
			if(this.opentype==0){
				return this.customFormConfirm(this.formData,formName)
			}
			if(this.opentype==2 && this.updateFrom){
				return this.updateFrom(this.formData,formName)
			}
			this.$refs[formName].validate(async valid => {
				if (valid) {
					await this.$axios(this.formRequestConfig.methods, this.formRequestConfig.url, this.formData, this.formRequestConfig.token);
					this.$message.success('保存成功！');
					this.$emit('fchange', false);
					this.formData={}
				} else {
					this.$message.error('请完善表单信息后重试！');
				}
			});
		},
		validationRules(editData, thiz) {
			if(editData.allowNull==false) return;
			let editType,valOption,textEditRule = [];
			if(editData.testType)
				editType = editData.testType;
			if(editData.testTypeOption)
				valOption = editData.testTypeOption;
			switch (editType) {
				case "number":
					textEditRule = [{
						required: true,
						validator: (rule, value, callback) => {
							if ((!value && value!=0) && !editData.allowNull) {
								return callback(new Error(editData.label + '不能为空'));
							}
							let val = /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value);
							if (!val) {
								callback(new Error('请输入合法的数字'));
							} else if (valOption) {
								if ("min" in valOption) {
									if (value < valOption.min)
										callback(new Error('输入值不能小于' + valOption.min));
									else
										callback();
								} else if ("max" in valOption) {
									if (value > valOption.max)
										callback(new Error('输入值不能大于' + valOption.max));
									else
										callback();
								} else if ("range" in valOption) {
									if (value > valOption.range[1] || value < valOption.range[0])
										callback(new Error('输入值介于' + valOption.range[0] + '和' + valOption.range[1] + '之间'));
									else
										callback();
								} else
									callback();
							}else
								callback(new Error('请指定输入值的范围!'));
						},
						trigger: ['blur','change']
					}]
					break;
				case "digits":
					textEditRule = [{
						required: true,
						validator: (rule, value, callback) => {
							if (!value && !editData.allowNull) {
								return callback(new Error(editData.label + '不能为空'));
							}
							if (!Number.isInteger(Number(value))) {
								callback(new Error('请输入整数'));
							} else if (valOption) {
								if ("min" in valOption) {
									if (value < valOption.min)
										callback(new Error('输入值不能小于' + valOption.min));
									else
										callback();
								} else if ("max" in valOption) {
									if (value > valOption.max)
										callback(new Error('输入值不能大于' + valOption.max));
									else
										callback();
								} else if ("range" in valOption) {
									if (value > valOption.range[1] || value < valOption.range[0])
										callback(new Error('输入值介于' + valOption.range[0] + '和' + valOption.range[1] + '之间'));
									else
										callback();
								} else
									callback();
							}else
								callback(new Error('请指定输入值的范围!'));
						},
						trigger: ['blur','change']
					}]
					break;
				case "percent":
					textEditRule = [{
						required: true,
						validator: (rule, value, callback) => {
							if (!value && !editData.allowNull) {
								return callback(new Error(editData.label + '不能为空'));
							}
							let reg = /^((\d+\.?\d*)|(\d*\.\d+))\%$/;
							if (!reg.test(value)) {
								callback(new Error('请输入百分比'));
							} else {
								callback()
							}
						},
						trigger: 'blur'
					}]
					break;
				case "mobile":
					textEditRule = [{
						required: true,
						validator: (rule, value, callback) => {
							let length = value.length,
								mobile = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[8-9]{1}))+\d{8})$/; //验证手机
							if (!value && !editData.allowNull) {
								return callback(new Error(editData.label + '不能为空'));
							}
							if ((length == 11 && mobile.test(value))) {
								callback()
							} else {
								return callback(new Error('手机号码格式错误！'));
							}
						},
						trigger: 'blur'
					}]
					break;
				case "phone":
					textEditRule = [{
						required: true,
						validator: (rule, value, callback) => {
							let length = value.length,
								phone = /^0\d{2,4}-?\d{7,8}$/, //验证固定电话
								salephone = /^((400)|(800))[0-9]{7}$/;
							if (!value && !editData.allowNull) {
								return callback(new Error(editData.label + '不能为空'));
							}
							if ((length >= 11 && length <= 13 && phone.test(value)) || (length == 10 && salephone.test(value))) {
								callback()
							} else {
								return callback(new Error('电话号码格式错误！（固定电话格式“区号-电话”）'));
							}
						},
						trigger: 'blur'
					}]
					break;
				case "email":
					textEditRule = [{
						required: true,
						validator: (rule, value, callback) => {
							let emReg =
								/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
							if (!value && !editData.allowNull) {
								return callback(new Error(editData.label + '不能为空'));
							}
							if (emReg.test(value)) {
								callback()
							} else {
								return callback(new Error('请输入正确的邮箱格式！'));
							}
						},
						trigger: 'blur'
					}]
					break;
				case "postal":
					textEditRule = [{
						required: true,
						validator: (rule, value, callback) => {
							let ptlReg = /^[a-zA-Z0-9 ]{3,12}$/g
							if (!value && !editData.allowNull) {
								return callback(new Error(editData.label + '不能为空'));
							}
							if (ptlReg.test(value)) {
								callback()
							} else {
								return callback(new Error('请输入正确的邮政编码！'));
							}
						},
						trigger: 'blur'
					}]
					break;
				default:
					textEditRule = [{
						required: true,
						message:((editData.showType=='imgUploader' || editData.showType=='mImgUploader')?'请上传':
						(editData.showType=='mSelect' || editData.showType=='select')?'请选择':
						editData.showType=='editor'?'请编辑':'请输入') + editData.label,
						trigger: ['blur','change']
					}]
					break;
			}
			thiz.$set(thiz.rules, editData.prop, textEditRule)
		}
	}
}
// <el-input v-if="item.showType=='password' && item.readtype == false" v-model="formData[item.prop]" autocomplete="off"></el-input>
// <el-input v-else-if="item.showType=='password' && (item.readtype == true || item.readtype == null)" disabled  autocomplete="off"></el-input>
// <el-checkbox v-if="item.showType=='password'" @change="changeReadType($event,item)" v-model="item.ischecked">是否编辑{{item.label}}</el-checkbox>
var formTpl = `<div><div class="dialog-box">
			<el-form :model="formData" :rules="rules" ref="formData">
				<el-row :gutter="20">
					<el-col :sm="20" :md="item.showType=='editor' ? 20 : 12" v-for="(item, index) in formDataConfig" v-bind:key="index">
						<el-form-item :label="item.label" :prop="item.prop" :label-width="formLabelWidth">
							<el-input v-if="item.showType=='textarea'" :disabled="item.disabled" :readonly="item.readOnly" v-model="formData[item.prop]" autocomplete="off" type="textarea" :placeholder="'请输入' + item.label"></el-input>
							
							<el-switch v-else-if="item.showType == 'swiper'" :disabled="item.disabled" v-model="formData[item.prop]"></el-switch>
							
							<el-input
								v-else-if="item.showType == 'number'"
								type="number"
								:disabled="item.disabled"
								:readonly="item.readOnly"
								v-model="formData[item.prop]"
								auto-complete="off"
								:placeholder="'请输入' + item.label"
							></el-input>
							
							<el-radio-group v-else-if="item.showType == 'radio'" :disabled="item.disabled" v-model="formData[item.prop]">
								<el-radio v-for="(ritem, rindex) in item.option" :label="ritem" v-bind:key="rindex"></el-radio>
							</el-radio-group>
							
							<avue-ueditor v-else-if="item.showType == 'editor'" v-model="formData[item.prop]" :options="aueditOptions" :id="'editor_id_' + opentype + item.prop" prop="item.prop"></avue-ueditor>
							<el-date-picker
								v-else-if="item.showType=='date'"
							    v-model="formData[item.prop]"
								:disabled="item.disabled"
							    :type="item.showType"
							    placeholder="选择日期"
								@change="dateChange($event,item)"
							    :picker-options="pickerOptions">
							</el-date-picker>
							
							<el-select v-else-if="item.showType == 'select'" :disabled="item.disabled" 
							v-model="formData[item.prop]"  @change="selectEvent($event,item)" placeholder="请选择">
								<el-option
								  v-for="(opitem,opindex) in item.option"
								  :key="opindex"
								  :label="item.lkey?opitem[item.lkey]:opitem.label"
								  :value="item.vkey?opitem[item.vkey]:opitem.value">
								</el-option>
							</el-select>
							<el-select v-else-if="item.showType == 'mSelect'" v-model="formData[item.prop]" multiple placeholder="请选择">
							    <el-option
							      v-for="(opitem,opindex) in item.option"
							      :key="opindex"
							      :label="item.lkey?opitem[item.lkey]:opitem.label"
							      :value="item.vkey?opitem[item.vkey]:opitem.value">
							    </el-option>
						    </el-select>
							<el-cascader v-else-if="item.showType == 'cascader'" :disabled="item.disabled" v-model="formData[item.prop]" :options="item.option" :props="{ expandTrigger: 'hover' }"></el-cascader>
														
							<ImgUpload v-else-if="item.showType=='imgUploader'" @oploadBack="successBack" @delcallback="delImgBack" :imgurl="uploadImgData" :items="item"></ImgUpload>
							
							<multipleImgUploader v-else-if="item.showType=='mImgUploader'" @oploadBack="successBack" @delcallback="delImgBack" :imgurl="muploadImgData" :items="item"></multipleImgUploader>
							
							<el-input v-else-if="item.showType=='password'" :disabled="item.disabled" :readonly="item.readOnly" v-model="formData[item.prop]" autocomplete="off" :placeholder="'请输入' + item.label" show-password></el-input>
							<div v-else-if="item.showType=='form'">
								<component v-bind:is="formComponentName" :selectdata="formData[item.prop]"></component>
							</div>
							<el-input @focus="tapInput(formData[item.prop],item.prop)" v-else-if="item.showType=='tapInput'" readonly v-model="formData[item.prop]" autocomplete="off" :placeholder="'请设置' + item.label"></el-input>
							<el-input v-else :readonly="item.readOnly" :disabled="item.disabled" v-model="formData[item.prop]" autocomplete="off" :placeholder="'请输入' + item.label"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div v-if="showTapInput">
				<component v-bind:is="tapInputComponentName" :selectdata="formData" :formconfig="formConfig" :opentype="opentype" @getVal="getTapInputVal"></component>
			</div>
		</div>
		
		<div class="btnArea">
			<el-button v-if="opentype!=3" size="small" @click="handleChangeClose">取 消</el-button>
			<el-button v-if="opentype==3" size="small" type="primary" @click="handleChangeClose">关 闭</el-button>
			<el-button v-if="opentype!=3" size="small" type="custom" @click="handleChangeConfirm('formData')">确 定</el-button>
		</div></div>`

export {
	myMixin,
	formTpl
}