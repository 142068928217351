const { formLabelConfig,formDataProp} = require('../config/userManager/userList.js');
module.exports = {
	data(){
		return{
			formDataConfig:formLabelConfig,//表单label配置
            // customUpdate:true,
		}
	},
	created(){
		// this.formData=formDataProp
		this.formRequestConfig.url = "/sys/user/saveOrUpdata"

        this.getUserlevel();
	},
	mounted(){
		
	},
	methods: {
        async getUserlevel(){
			const res = await this.$axios('POST',"/sys/userLevel/list",{},true)
			this.formDataConfig.map(v=>{
				if(v.prop=='userLevelId'){
					v.option = res
					v.lkey="levelName"
					v.vkey="levelId"
				}
			})
			this.$forceUpdate();
		},
	},
	components: {
		
	},
}