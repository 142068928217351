const { formLabelConfig} = require('../config/store/ProductSwiperImg.js');
module.exports = {
	data(){
		return{
			formDataConfig:formLabelConfig,//表单label配置
			tapInputComponentName:"SelectProduct"
		}
	},
	created(){
		this.formRequestConfig.url = "/sys/indexImg/submit"
	},
	methods: {
		getTapInputVal(e){
			this.formData.relation = e.val
			// this.formData.relationName  = e.name
			this.$set(this.formData,"relationName",e.name)
			this.$forceUpdate()
		},
	},
	components: {
		SelectProduct: () => import('@/components/businessComponent/selectProduct.vue')
	},
}