const { formLabelConfig,formDataProp} = require('../config/system/user.js');
module.exports = {
	data(){
		return{
			formDataConfig:formLabelConfig,//表单label配置
			customUpdate:true,
			formData:formDataProp
			
		}
	},
	created(){
		this.formRequestConfig.url = "/sys/yunke/mgr/insertAdmin"
		this.formDataConfig.map(v=>{
			if(v.prop == 'password'){
				v.disabled = this.opentype==2 ? true : false
			}
		})
	
	},
	methods: {
		updateFrom(data,formName){
			this.$refs[formName].validate(async valid => {
				if (valid) {
					await this.$axios('POST',"/sys/yunke/mgr/updateAdminInfo",data,true);
					this.$message.success('保存成功！');
					this.$emit('fchange', false);
					this.formData={}
				} else {
					this.$message.error('error submit!!');
				}
			});
		},
	},
	components: {
		
	},
}