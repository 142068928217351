const { formLabelConfig,formDataProp} = require('../config/store/ProductStandard.js');
module.exports = {
	data(){
		return{
			formDataConfig:formLabelConfig,//表单label配置
			// customUpdate:true,
		}
	},
	created(){
		this.formData=formDataProp
		this.formRequestConfig.url = "/sys/prodProp/save"
	},
	mounted(){
		
	},
	methods: {
		updateFrom(data,formName){
			this.$refs[formName].validate(async valid => {
				if (valid) {
					await this.$axios('POST',"/sys/prodProp/update",data,true);
					this.$message.success('保存成功！');
					this.$emit('fchange', false);
					this.formData={}
				} else {
					this.$message.error('请完善表单信息后重试！');
				}
			});
		},
	},
	components: {
		
	},
}