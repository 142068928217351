const { formLabelConfig} = require('../config/orderManager/orderList.js');
module.exports = {
	data(){
		return{
			formDataConfig:formLabelConfig,//表单label配置
			customUpdate:true,
			formLabelWidth:"120px",
			// formComponentName:"productSkuList",
		}
	},
	created(){
	
	},
	mounted(){
		
	},
	methods: {
		
	},
	// components: {
	// 	"productSkuList": () => import("@/components/customForm/productSkuList.vue")
	// },
}