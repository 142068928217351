module.exports = {
	methods:{
		getCategoryTree(field) {
			console.log(field)
			return 2
		},
		submitForm(data){
			console.log(data)
			this.$emit('fchange', false);
		}
	}
}
