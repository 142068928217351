<template>
	<div>
		<eleForm 
		:opentype="opentype" 
		:selectdata="selectdata" 
		:formConfig="formConfig"
		@schange="handleChangeClose"
		@fchange="handleChangeConfirm">
		</eleForm>
		<!-- <component v-bind:is="cname"></component></transition> -->
	</div>
</template>

<script>
	import {myMixin,formTpl} from '../assets/js/elementFormBase.js'
	import {$vue} from "../main.js"
	import Vue from 'vue';
	import axios from 'axios'
	var customform = "",
		formName = "";
	export default {
		props: {
			opentype: {
				type: Number,
				default: 0
			},
			selectdata: {//选中列表传过来的数据
				type: Object,
				default: () => {}
			},
			formConfig: {//表单配置
				type: Object,
				default: () => {}
			}
		},
		created() {
			customform  = this.$route.query.customform
			formName = this.formConfig.formName
			this.createComponent()
		},
		methods:{
			handleChangeClose() {
				this.$emit('schange', false);
			},
			handleChangeConfirm() {
				this.$emit('fchange', false);
			},
			createComponent(){
				console.log(formName)
				Vue.component("eleForm", function(resolve, reject) {
					var customMixin={}
					if(customform==2){//请求网络自定义js文件
						axios.get(`./customformjs/${formName}.js`)
						.then(function(res) {
							eval(res.data);
							resolve({
								mixins: [myMixin,customMixin],
								template:formTpl
							})
						})
					}else{
						if(customform==1)
							customMixin=require(`@/assets/customformjs/${formName}.js`);
						resolve({
							mixins: [myMixin,customMixin],
							template:formTpl
						})
					}
				});
			}
		}
	}
</script>

<style>

</style>
